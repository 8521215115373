import {
	Button,
	Errors,
	Field,
	FieldDescription,
	FieldErrors,
	Heading,
	Input,
	Label,
	Option,
	Radio,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	Select,
	TextField,
	Container,
	Breadcrumbs,
	BreadcrumbItem,
	Section,
	Page,
} from '@troon/ui';
import { createAsync, useLocation, useSubmission } from '@solidjs/router';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import dayjs from 'dayjs';
import { Formspree, formspreeAction } from '@troon/forms';
import { Icon } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { useUser } from '../../../providers/user';
import { getConfigValue } from '../../../modules/config';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { getFacilities, requestClubs } from './_data';
import type { FacilityAmenitiesFragment } from '../../../graphql';

export default function RequestATeeTime() {
	const user = useUser();
	const [guests, setGuests] = createSignal(0);
	const [members, setMembers] = createSignal(0);
	const [requestingClub, setRequestingClub] = createSignal<string | undefined>();
	const submission = useSubmission(requestTeeTime);
	const data = createAsync(() => getFacilities(), { deferStream: true });
	const loc = useLocation();

	return (
		<>
			<Title>Amenities Request | Troon Privé Member Programs | Troon</Title>
			<Container>
				<div class="pt-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
						<BreadcrumbItem href="/member-programs/private-club-amenities">Private Club Amenities</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Amenities Request</BreadcrumbItem>
					</Breadcrumbs>
				</div>

				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Icon name="building-01-custom" class="size-24" />
								<Heading as="h1" size="h2">
									Amenities Request Form
								</Heading>

								<p>Please complete the form below to request usage of amenities at one of our Troon Privé clubs.</p>

								<p class="text-sm italic text-neutral-800">
									*Private club members experiencing difficulty with accessing Troon Privé Privileges must contact the
									appropriate staff at his or her own club.
								</p>
							</Section>
						</GridFive>

						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Switch>
								<Match when={submission.result}>
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<Icon name="circle-check" class="text-8xl text-brand" />
										<Heading as="h1" size="h2">
											Request received!
										</Heading>
										<p class="text-lg">
											Thank you for contacting us to request amenities usage at one of our Troon Privé clubs. Your
											destination club will be in touch with you to confirm your reservation.
										</p>
									</div>
								</Match>
								<Match when>
									<Formspree action={requestTeeTime} class="flex flex-col gap-6">
										<div class="flex w-full flex-wrap gap-6">
											<TextField name="First name" required class="grow">
												<Label>First name</Label>
												<Input autocomplete="given-name" value={user()?.me.firstName} />
											</TextField>

											<TextField name="Last name" required class="grow">
												<Label>Last name</Label>
												<Input autocomplete="family-name" value={user()?.me.lastName} />
											</TextField>
										</div>

										<TextField name="Email" required>
											<Label>Email address</Label>
											<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
										</TextField>

										<TextField name="Phone" required>
											<Label>Phone number</Label>
											<Input autocomplete="tel" inputMode="tel" />
										</TextField>

										<Field name="Home club" required>
											<Label>Your home club</Label>
											<Select>
												<Option disabled selected class="hidden" />
												<For each={homeClubs}>{(club) => <Option>{club}</Option>}</For>
											</Select>
											<FieldErrors />
										</Field>

										<TextField name="Home club membership #" required>
											<Label>Home club membership #</Label>
											<Input />
										</TextField>

										<Field name="Desired club" required>
											<Label>Club you desire to visit</Label>
											<Select onChange={(e) => setRequestingClub(e.currentTarget.value)}>
												<Option disabled selected class="hidden" />
												<For each={data()?.facilities.facilities}>
													{(facility) => <Option value={facility.name}>{facility.name}</Option>}
												</For>
											</Select>
											<FieldErrors />
										</Field>

										<Show
											when={
												requestingClub() && data()?.facilities.facilities.find((fac) => fac.name === requestingClub())
											}
										>
											{(facility) => (
												<Field name="Activity" required>
													<Label>Activity</Label>
													<Select>
														<Option disabled selected class="hidden" />
														<For each={Object.entries(amenities)}>
															{([key, amenity]) => (
																<Show
																	when={requestClubs[(facility() as FacilityAmenitiesFragment).slug]?.includes(key)}
																>
																	<Option value={amenity.title}>{amenity.title}</Option>
																</Show>
															)}
														</For>
													</Select>
													<FieldErrors />
												</Field>
											)}
										</Show>

										<TextField name="Date and time" required>
											<Label>Date and time you desire to visit</Label>
											<Input type="datetime-local" min={dayjs().format('YYYY-MM-DDThh:mm')} />
										</TextField>

										<RadioGroup name="Number of members" onSelect={(value) => setMembers(parseInt(value, 10) - 1)}>
											<Label>Total number of members</Label>
											<RadioBar>
												<RadioBarButton value="1" checked>
													<Label>1</Label>
												</RadioBarButton>
												<RadioBarButton value="2">
													<Label>2</Label>
												</RadioBarButton>
												<RadioBarButton value="3">
													<Label>3</Label>
												</RadioBarButton>
												<RadioBarButton value="4">
													<Label>4</Label>
												</RadioBarButton>
											</RadioBar>
											<FieldDescription>
												Select the total number of members <em>including yourself</em>.
											</FieldDescription>
										</RadioGroup>

										<Show when={members() > 0}>
											<fieldset class="mb-8 flex flex-col gap-4 border-b border-neutral pb-8">
												<legend class="text-lg font-semibold">
													Please enter the first and last name of each of your accompanying guests
												</legend>
												<For each={[...Array(members()).keys()]}>
													{(_, i) => (
														<TextField name={`Accompanying member ${i() + 1}`}>
															<Label>Accompanying member {i() + 1}</Label>
															<Input />
														</TextField>
													)}
												</For>
											</fieldset>
										</Show>

										<RadioGroup name="Number of guests" onSelect={(value) => setGuests(parseInt(value, 10))}>
											<Label>Total number of guests</Label>
											<RadioBar>
												<RadioBarButton value="0" checked>
													<Label>0</Label>
												</RadioBarButton>
												<RadioBarButton value="1">
													<Label>1</Label>
												</RadioBarButton>
												<RadioBarButton value="2">
													<Label>2</Label>
												</RadioBarButton>
												<RadioBarButton value="3">
													<Label>3</Label>
												</RadioBarButton>
												<RadioBarButton value="4">
													<Label>4</Label>
												</RadioBarButton>
											</RadioBar>
										</RadioGroup>

										<Show when={guests() > 0}>
											<fieldset class="mb-8 flex flex-col gap-4 border-b border-neutral pb-8">
												<legend class="text-lg font-semibold">
													Please enter the first and last name of each of your accompanying guests
												</legend>
												<For each={[...Array(guests()).keys()]}>
													{(_, i) => (
														<TextField name={`Accompanying guest ${i() + 1}`}>
															<Label>Accompanying guest {i() + 1}</Label>
															<Input />
														</TextField>
													)}
												</For>
											</fieldset>
										</Show>

										<TextField name="Additional comments">
											<Label>Additional comments</Label>
											<Input multiline />
										</TextField>

										<RadioGroup name="Subscribe to Troon Privé Magazine" required>
											<Label>Please subscribe me to Troon Privé Magazine</Label>
											<Radio value="Yes">
												<Label>Yes</Label>
											</Radio>
											<Radio value="No">
												<Label>No</Label>
											</Radio>
										</RadioGroup>

										<RadioGroup name="Receive special offers" required>
											<Label>
												I would like to receive special offers from Troon and its managed facilities and preferred
												partners
											</Label>
											<Radio value="Yes">
												<Label>Yes</Label>
											</Radio>
											<Radio value="No">
												<Label>No</Label>
											</Radio>
										</RadioGroup>

										<Errors />

										<Button class="w-fit" type="submit">
											Submit request
										</Button>
									</Formspree>
								</Match>
							</Switch>
						</GridSeven>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const requestTeeTime = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'prive-amenities');

const homeClubs = [
	'Alpine Country Club (RI)',
	'Alpine Country Club (UT)',
	'Audubon Country Club',
	'Austin Country Club',
	'Aviara Golf Club',
	'Bahia Beach Resort & Golf Club',
	'BallenIsles Country Club',
	'Bay Creek',
	'Bayside Resort Golf Club',
	'Belfair',
	'Blackstone Country Club at Vistancia',
	'Boca Grove',
	'Bonita National Golf & Country Club',
	'Bookcliff Country Club',
	'Boulder Ridge Golf Club',
	'Boulders Resort & Spa',
	'Broken Top',
	'Calvert Crossing Golf Club',
	'Capital Canyon Club',
	'Centurion Club',
	'Champion Hills',
	'Cimarron Hills Golf & Country Club',
	'Colonial Country Club (FL)',
	'Columbia Country Club',
	'Columbus Country Club',
	'Contra Costa Country Club',
	'Deering Bay Yacht & Country Club',
	'Dunes of Naples',
	'Eau Claire Golf & Country Club',
	'Echo Valley Country Club',
	'El Macero Country Club',
	'Entrada at Snow Canyon Country Club',
	'Esplanade Golf & Country Club',
	'Fairbanks Ranch Country Club',
	'Fairwood Golf & Country Club',
	'Fargo Country Club',
	'Fountaingrove Club',
	'Gadsden Country Club',
	'Grande Dunes Ocean Club',
	'Hunting Creek Country Club',
	'John Newcombe Country Club',
	'Kankakee Country Club',
	'Kemper Lakes Golf Club',
	'Knollwood Country Club (MI)',
	'La Cantera Resort & Spa',
	'Lake of Isles',
	'Lakeshore Yacht & Country Club',
	'Lomas Santa Fe Country Club',
	'Manchester Country Club',
	'Marin Country Club',
	'Mill Creek Country Club',
	'Naperville Country Club',
	'NorthRiver Yacht Club',
	'Ocean Edge Resort & Golf Club',
	'Oldfield Club',
	'Old Overton Club',
	'Oneida Golf & Country Club',
	'Parkland Golf & Country Club',
	'Phoenix Country Club',
	'Pine Canyon Club',
	'Pine Tree Country Club',
	'Pinetop Country Club',
	'Pronghorn',
	'Raintree Country Club',
	'Raritan Valley Country Club',
	'Red Hawk Golf and Resort',
	'Rio Mar Country Club',
	'Rio Verde Country Club',
	'Salina Country Club',
	'Santa Ana Country Club',
	'Santa Maria Golf & Country Club',
	'Seminole Legacy',
	'Silver Creek Valley Country Club',
	'Silverado Resort',
	'Somerby Golf Club',
	'Southern Trace Country Club',
	'South Side Country Club',
	'Springdale Golf Club',
	'Sterling Grove',
	'StoneTree Golf Club',
	'Sunnyside Golf & Country Club',
	'Talking Rock',
	'Terravita Golf Club',
	'Tetherow Golf Club',
	'The Club at ArrowCreek',
	'The Club at Castlewood',
	'The Club at Cordillera',
	'The Club at Crested Butte',
	'The Club at Hammock Beach',
	'The Club at Porto Cima',
	'The Clubs at St. James',
	'The Falls Club',
	'The Golf Club at Lansdowne',
	'The King Kamehameha Golf Club',
	'The Peninsula Golf & Country Club',
	'The Powder Horn Golf Club',
	'The Ritz-Carlton Key Biscayne',
	'The Standard Club',
	'The Westin La Paloma Resort & Spa',
	'Tiburón Golf Club',
	'Tumble Brook Country Club',
	'Tyler Athletic and Swim',
	'Vanderbilt Legends Club',
	'Wausau Country Club',
	'West Bay Club',
	'Westchester Hills Golf Club',
	'Westhaven Golf Club',
	'Westmoreland Country Club',
	'Wickenburg Ranch Golf & Social Club',
	'Wilderness Club Resort',
	'Winchester Country Club',
	'Woodland Hills Country Club',
	'Yorba Linda Country Club',
];

const amenities = {
	dining: { title: 'Dining' },
	fitness: { title: 'Fitness' },
	tennis: { title: 'Tennis' },
	spa: { title: 'Spa' },
	swimming: { title: 'Swimming' },
};
